import { useEffect, useState } from "react"
import { TimelineComponent } from "../components/timeline"
import { dummy } from "../dummydata/faker"

export const Agenda = () => {
    const [filteredEvents, setFilteredEvents] = useState<any>()
    useEffect(() => { // review this code please 
        let temp = dummy?.events
        setFilteredEvents(temp)
    },)

    return (<div className="flex w-full justify-center p-3 h-full">
        <div className="flex w-1/2">
            <TimelineComponent onEventClick={() => { }} events={filteredEvents} />
        </div>
    </div>)
}