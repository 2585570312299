import { useState } from 'react'
// import { color, spacing } from "../../theme"
interface prop {
    key: any,
    title: any
}
export const InterestItem = (props: prop) => {
    const [isHighlighted, setIsHighlighted] = useState(true)
    const [backgroundColor, setBackgroundColor] = useState("bg-white")
    const [titleColor, setTitleColor] = useState("text-goround")
    // const [containerWidth, setContainerWidth] = useState(null)

    function handleClick() {
        console.log(props.title);
        if (isHighlighted) {
            setBackgroundColor("bg-goround")
            setTitleColor("text-white")
            setIsHighlighted(false)
        }
        else {
            setBackgroundColor("bg-white")
            setTitleColor("text-goround")
            setIsHighlighted(true)
        }
    }
    return (
        <div className={`${titleColor} hover:cursor-pointer ${backgroundColor} shadow-md p-1 w-fit m-1 flex rounded-lg`} key={props.key}
            onClick={handleClick}
        >
            {props.title}
        </div>

    )
}