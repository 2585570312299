import moment from 'moment';
import { useEffect, useState } from 'react';
import { Calendar as CalendarComponent } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useNavigate } from 'react-router-dom';
import { TimelineComponent } from '../components/timeline';
import { dummy } from '../dummydata/faker';


export const Calendar = (props: any) => {
    const navigate = useNavigate()

    const [selectedDate, setSelectedDate] = useState<any>()

    useEffect(() => {
        console.log(dummy.events.map((obj) => moment(obj.date).format('DD-MM-YYYY')));

    }, [])
    function highlightStyle(val: any): any {
        if (dummy.events.map((obj) => obj.date).find(x => x === moment(val.date).format("DD-MM-YYYY"))) {
            return 'highlight'
        }
    }
    function handleClick(e: any) {
        setSelectedDate(moment(e).format('DD-MM-YYYY'))
    }
    function onEventSelect(id: any) {
        navigate('/event/' + id + '/overview')
    }
    const [filteredEvents, setFilteredEvents] = useState<any>()
    // const [selectedDate, setSelectedDate] = useState<any>()
    useEffect(() => {
        setSelectedDate(props.selectedDate)
    }, [props.selectedDate])
    useEffect(() => { // review this code please 
        let temp = dummy?.events?.filter((obj) => obj?.date === selectedDate)
        setFilteredEvents(temp)
    },
        [selectedDate])
    return (<div className='flex w-full justify-center items-center gap-3 flex-col py-3'>
        <CalendarComponent
            className={"text-goround gap-3"}
            tileClassName={highlightStyle}
            calendarType="US"
            onClickDay={handleClick}
        />
        <TimelineComponent onEventClick={onEventSelect} events={filteredEvents} />
    </div>)
}