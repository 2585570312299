import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, deleteDoc, doc, getDoc, getDocs, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import store, { agoraActions } from "./store";

// agora token is fetched from firebase, same as mobile app, al you have to do is update the expired token in firestore 


const initialAgoraState = {
    option: {
        appID: "95a6c177413445189557b795537834bc",
        channel: "hello",
        uid: null,
        token: "",
        key: '',
        secret: ''
    },
    myUid: 0,
    moderators: [
    ],
    attendees: [],
    totalAmount: 0,
    token: 0,
    activeSpeakers: ["22"]
}
export const agoraSlice = createSlice({
    name: 'agoraSlice',
    initialState: initialAgoraState,
    reducers: {
        setUid(state: any) {
            if (localStorage.getItem("uid")) {
                state.myUid = localStorage.getItem("uid")
            }
            else {
                let temp = Math.round(Math.random() * 10000000).toString()
                localStorage.setItem("uid", temp)
                state.myUid = temp
            }

        },

        addPeer(state: any, action) {
            if (action.payload.moderator) {
                state.moderators = [...state.moderators, action.payload]
            }
            else {
                state.attendees = [...state.attendees, action.payload]
            }
            // state.totalAmount = state.totalAmount + action.payload.price
        },
        removePeer(state, action) {

        },
        setPeers(state, action) {
            state.moderators = action.payload;
            state.attendees = action.payload;
        },
        getUid(state: any) {
            return state.myUid
        },
        setToken(state: any, action) {
            state.option.token = action.payload
        }, setActiveSpeakers(state: any, action) {
            console.log('----------------------==-=--=', action.payload);
            state.activeSpeakers = action.payload
        }
    }

})
export const insertPeerToFirebase = createAsyncThunk('agora/insert', async (role: any) => { // true = moderator - false = attendee
    store.dispatch(agoraActions.setUid())
    let uid = store.getState().agora.myUid
    await setDoc(doc(db, "peers", uid.toString()), {
        moderator: role,
        muted: true,
        uid: uid,
        raiseHand: false
    });

})

export const getToken = createAsyncThunk('agora/getToken', async () => {
    const querySnapshot = await getDocs(collection(db, "token"));
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        store.dispatch(agoraActions.setToken(doc.data().token))
    });
})

export const deletePeerFromFirebase = createAsyncThunk('agora/delete', async () => {
    let uid = store.getState().agora.myUid
    await deleteDoc(doc(db, "peers", uid.toString())).then(() => {
    }).catch((err) => {
        console.log('errorrrr deleting', err);
    });
})
export const raiseHand = createAsyncThunk('agora/raise', async () => {
    const docRef = doc(db, "peers", store.getState().agora.myUid.toString());
    const docSnap = await getDoc(docRef);
    console.log(docSnap.data()?.raiseHand);
    await updateDoc(doc(db, "peers", store.getState().agora.myUid.toString()), {
        raiseHand: !docSnap.data()?.raiseHand
    });
}) 