
import faker from 'faker'
export const songsdata = [
    {
        "title": "Podcast 1",
        "url": "https://beardbarnmusicbucket.s3.amazonaws.com/The+Wild+Horse",
        "image": faker.image.city()
    },
    {
        "title": "Podcast 2",
        "url": "https://firebasestorage.googleapis.com/v0/b/authentication-50462.appspot.com/o/Wegz%20-%20ElBakht%20_%20%D9%88%D9%8A%D8%AC%D8%B2%20-%20%D8%A7%D9%84%D8%A8%D8%AE%D8%AA%20(Audio)%20prod.%20Rahal.mp3?alt=media&token=67edccc6-6dbe-407c-8457-f09e0b8804f1",
        "image": faker.image.city()

    },
    {
        "title": "Podcast 3",
        "url": "https://beardbarnmusicbucket.s3.amazonaws.com/The+Wild+Horse",
        "image": faker.image.city()

    }
]