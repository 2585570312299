import { dummy } from "../dummydata/faker"
// @ts-ignore
import ReactImageAppear from 'react-image-appear';
import { ImageLoading } from "./ImageLoading";
export const ArticleCard = (props: any) => {
    return (
        <div onClick={() => props.onClick(props.index)} key={props.index} className="article-card flex">
            <ImageLoading className="rounded-xl flex w-2/6" style={{ objectFit: "cover", width: "100px" }} src={props.img} />
            <div className="flex flex-col w-4/6">
                <h4 className="font-light ">Lorem Ipsum</h4>
                <div className="font-light text-gray-500">{dummy.lorem(3)}</div>
            </div>
        </div>
    )
}