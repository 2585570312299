import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
export const ImageLoading = (props: any) => {
    const [show, setShow] = useState(false)
    return (
        <>
            <div  {...props}>
                {<img alt='unavailable' onAbort={() => { setShow(true) }} onLoad={() => setShow(true)} src={props.src} className="rounded-xl object-cover w-full flex h-full" style={{ display: show ? 'flex' : 'none' }} />}
                {!show && <div className='self-center w-full h-full flex justify-center items-center'><Spinner animation='border' /></div>}
            </div>
        </>
    )
}