import faker from 'faker'
import { FaCalendar, FaHeart, FaHome, FaPaperPlane, FaPeopleArrows } from 'react-icons/fa'
import { BiLibrary } from 'react-icons/bi'
import { Interest, SidebarItem } from '../interfaces/interfaces'
import moment from 'moment'
let countries: Interest[] = [{ label: "Saudia arabia", value: "Saudia arabia" }]

for (let i = 0; i <= 10; i++) {
    countries[i] = { label: faker.address.country(), value: faker.address.country() }
}

let tempMedical: string[] = ["Ophthalmology", "Diabetes", "Dermatology", "Urology", "Surgery", "Psychiatry", "Oncology", "Immunology", "Neurology", "Anesthesiology", "Emergency medicine", "Obstetrics and gynecology"];
let medical: any[] = []

for (let i = 0; i < tempMedical.length; i++) {
    medical.push({ label: tempMedical[i], value: tempMedical[i] })
}
let bookImages = ["https://www.adobe.com/express/create/cover/media_181e3d2c78f153ae7bf0e19a2faeb9a76e234da30.jpeg?width=400&format=jpeg&optimize=medium"
    , "https://s26162.pcdn.co/wp-content/uploads/2019/12/46301955-668x1024.jpg",
    "https://rivetedlit.com/wp-content/uploads/2018/09/five-feet-apart-9781534437333_hr-679x1024.jpg",
    "https://rivetedlit.com/wp-content/uploads/2020/01/all-this-time-9781534466340_xlg.jpg"
    , "https://d15fwz9jg1iq5f.cloudfront.net/wp-content/uploads/2020/02/06200225/Ninth-Life_final.jpg",
    "https://i.pinimg.com/736x/29/88/8d/29888ddd30d450cfcd07b33de0899e19.jpg"
    , "https://i.pinimg.com/736x/a0/e6/26/a0e626af3883e109da440ec021080f99.jpg",
    "https://www.creatopy.com/blog/wp-content/uploads/2020/08/minimalist-book-covers-no-longer-human.jpg"]
let sideBar: SidebarItem[] = [
    { path: "Home", value: "Home", icon: FaHome },
    { path: "Library/Books", value: "Library", icon: BiLibrary },
    { path: "Favorites/Books", value: "Favorites", icon: FaHeart },
    { path: "Calendar", value: "Calendar", icon: FaCalendar },
    { path: "Request", value: "Request", icon: FaPaperPlane },
    { path: "Discussions", value: "Discussions", icon: FaPeopleArrows }]
let lastNews: string[] = []
for (let i = 0; i < 10; i++) {
    lastNews[i] = faker.image.image()
}
let events: any[] = []
let time: any[] = ["1:10", "2:30", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:30"]
let endTime: any[] = ["1:30", "2:50", "3:30", "4:30", "5:30", "6:30", "7:30", "8:30", "9:30", "11:00"]
for (let i = 0; i < 10; i++) {
    events[i] = {
        id: i,
        agenda: [{ time: '03:00', endTime: '03:05', title: 'agenda 1' }, { time: '03:05', endTime: '03:10', title: 'agenda 2' }, { time: '03:10', endTime: '03:20', title: 'agenda 3' }, { time: '03:20', endTime: '03:30', title: 'agenda 4' }],
        date: i % 2 ? moment(faker.date.soon(4)).format('DD-MM-YYYY').toString() : moment(faker.date.recent(10)).format('DD-MM-YYYY').toString(),
        time: time[i],
        endTime: endTime[i],
        title: faker.name.title(),
        description: faker.lorem.lines(1),
        speakers: [{
            name: faker.name.findName(),
            description: faker.lorem.lines(3),
            image: 'https://img.freepik.com/free-photo/pleased-young-female-doctor-wearing-medical-robe-stethoscope-around-neck-standing-with-closed-posture_409827-254.jpg?t=st=1647165169~exp=1647165769~hmac=3f4d90fa062b1725f5178d9e55e5b703a0e2f0aface69cae56fd5b3add60b120&w=2000'
        },
        {
            name: faker.name.findName(),
            description: faker.lorem.lines(3),
            image: 'https://img.freepik.com/free-photo/pleased-young-female-doctor-wearing-medical-robe-stethoscope-around-neck-standing-with-closed-posture_409827-254.jpg?t=st=1647165169~exp=1647165769~hmac=3f4d90fa062b1725f5178d9e55e5b703a0e2f0aface69cae56fd5b3add60b120&w=2000'
        }]
    }
}

let notifications: any[] = []
for (let i = 0; i < 10; i++) {
    notifications.push({ label: faker.lorem.lines(1), actions: true, image: faker.image.abstract() })
}
let categories: string[] = ['Gallery', 'Podcasts', 'Books', 'Articals', 'Videos']
function lorem(lines: number) {
    return faker.lorem.lines(lines)
}
let podcasts: any[] = []
for (let i = 0; i < 10; i++) {
    podcasts.push({ label: faker.lorem.lines(1), actions: true, image: faker.image.abstract() })
}
let videoImages = ["https://st2.depositphotos.com/1017986/8169/i/950/depositphotos_81694318-stock-photo-group-of-happy-doctors-meeting.jpg"
    , "https://st2.depositphotos.com/1518767/5391/i/450/depositphotos_53913295-stock-photo-doctors-using-a-laptop-together.jpg"
    , "https://beterra.com/wp-content/uploads/Pasted-image-at-2017_03_09-08_31-AM-2-1024x683.png",
    "https://t4.ftcdn.net/jpg/02/09/72/89/360_F_209728910_IZJSn18pBx06KtmcNqnMv81juldUG5NP.jpg"
    , "https://cdn2.hubspot.net/hubfs/2897117/Stock%20images/Team%20of%20doctors%20having%20a%20meeting%20in%20medical%20office.jpeg",
    "https://st.focusedcollection.com/16485780/i/650/focused_199749500-stock-photo-doctors-business-people-talking-meeting.jpg"]

let discussions = []
for (let i = 0; i < 6; i++) {
    discussions.push({
        title: 'title',
        doctors: ["Dr / 1 ", "Dr / 2 "],
        followers: 500,
        likes: 20,
        time: new Date(),
        images: ["https://img.freepik.com/free-photo/pleased-young-female-doctor-wearing-medical-robe-stethoscope-around-neck-standing-with-closed-posture_409827-254.jpg?t=st=1647165169~exp=1647165769~hmac=3f4d90fa062b1725f5178d9e55e5b703a0e2f0aface69cae56fd5b3add60b120&w=2000",
            "https://img.freepik.com/free-photo/pleased-young-female-doctor-wearing-medical-robe-stethoscope-around-neck-standing-with-closed-posture_409827-254.jpg?t=st=1647165169~exp=1647165769~hmac=3f4d90fa062b1725f5178d9e55e5b703a0e2f0aface69cae56fd5b3add60b120&w=2000"],
        details: faker.lorem.lines(1)
    })
}

let moderators = []
for (let i = 0; i < 6; i++) {
    moderators.push({ moderator: true, muted: i % 2 ? false : true })
}
export const dummy = {
    countries,
    medical,
    sideBar,
    lastNews,
    categories,
    lorem,
    notifications,
    events,
    bookImages,
    videoImages,
    discussions,
    moderators
}