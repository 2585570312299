
import { useNavigate } from 'react-router-dom'
import { DiscussionCard } from '../components/discussion-card'
import { UpcomingDiscussionCard } from '../components/upcoming-discussion-card'
import { dummy } from '../dummydata/faker'
export const Discussions = (props: any) => {
    const navigate = useNavigate()
    function navigateDiscussion(index: any) {
        navigate(`${index}`)
    }
    function navigateUpcoming(index: any) {

    }
    return (<div className='flex flex-col h-full'>
        <div className='flex flex-col h-full w-full'>
            <div className='flex flex-col w-full h-2/3 p-7'>
                <h4>Live discussion</h4>
                <div className='grid grid-cols-3 w-full gap-6 h-full'>
                    {dummy.discussions.map((discussion, index) => {
                        return (<DiscussionCard onClick={() => navigateDiscussion(index)} item={discussion} />)
                    })}
                </div>
            </div>
            <div className='flex flex-col w-full h-full p-7' >
                <h4>Upcoming discussion</h4>
                <div className='grid w-full justify-center gap-10 px-24 grid-cols-3'>
                    {dummy.discussions.slice(0, 3).map((discussion, index) => {
                        return (<UpcomingDiscussionCard onClick={() => navigateUpcoming(index)} item={discussion} />)
                    })}

                </div>
            </div>
        </div>
    </div>)
}