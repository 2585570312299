import Input from "react-select"
import { dummy } from "../dummydata/faker"
import { useNavigate } from "react-router-dom"
import { GoroundIcon } from "../assets/icons/GoroundIcon"
export const SignUp = () => {
    const navigate = useNavigate()
    function next() {
        navigate("/interests")
    }
    return (<div
        className="flex justify-center m-3 flex-col items-center">
         <div className="w-100 justify-start rounded-none flex">
            <div className="flex w-2/12 h-fit ">
                <GoroundIcon vertical />
            </div>
        </div>
        <div>
            <h1 className="text-2xl font-bold text-goround">Sign up</h1>
        </div>
        <div className="flex w-full px-56 flex-col">
            <form className="flex flex-row">
                <input placeholder="First name *" required className="input-primary-large  w-1/2 " type={"text"} />
                <input placeholder="Last name *" required className="input-primary-large  w-1/2 " type={"text"} />
            </form>
            <form className="flex flex-row">
                <div className="flex w-1/2 ">
                    <input placeholder="Username *" required className="input-primary-large " type={"text"} />
                </div>
                <div className="flex w-1/2">
                    <input placeholder="Birthdate *" required className="input-primary-large " type={"date"} />
                    <input placeholder="Email *" required className="input-primary-large " type={"email"} />
                </div>
            </form>
            <form className=" flex flex-row justify-center align-center items-center ">
                <div className="flex w-1/2">
                    <input placeholder="Phone number *" required className="input-primary-large" type={"tel"} />
                </div>
                <div className="flex w-1/2">
                    <Input className="rounded-xlg input-primary-large p-0" placeholder="Country *" options={dummy.countries} />
                    <input placeholder="Town *" className="input-primary-large" type={"text"} />
                </div>
            </form>
            <form className="flex flex-row">
                <input placeholder="Hospital *" required className="input-primary-large" type={"text"} />
                <input placeholder="Speciality *" className="input-primary-large" type={"text"} />
            </form>
            <form className="flex flex-row">
                <input placeholder="Password *" required className="input-primary-large" type={"password"} />
                <input placeholder="Confirm password *" required className="input-primary-large" type={"password"} />
            </form>
            <div className="flex w-full my-3 justify-center">
                <button onClick={next} className="btn-primary w-2/12 flex justify-center">Next</button>
            </div>
        </div>

    </div>)
}