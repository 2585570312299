import { useNavigate } from "react-router-dom"

export const Request = (props: any) => {
    const navigate = useNavigate()
    function handleRequest() {
        navigate('/requestSuccess')
    }
    return (<div className="flex 11/12 flex-col h-full">
        <div className="flex justify-between w-11/12">
            <div className="flex w-full justify-between m-3">
                <div className=" m-3">
                    <h3 className="text-goround ">Request</h3>
                    <h5 className="text-goround">How can we help you?</h5>
                </div>
                <div className="mt-4">
                    <button onClick={handleRequest} className="btn-primary text-xl p-2">Send request</button>
                </div>
            </div>

        </div>

        <div className="flex flex-col w-full h-full">
            <input className="input-primary-large shadow-none w-3/4" placeholder="Subject *" />
            <textarea className="input-primary-large  w-11/12 h-full shadow-none justify-start align-top items-start" placeholder="Inquiry *" />
        </div>
    </div>)
}