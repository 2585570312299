import { dummy } from "../dummydata/faker"
import { ImageLoading } from "../components/ImageLoading";

import { FaHeadSideVirus, FaLink, FaPuzzlePiece, FaRocket } from "react-icons/fa";
import { GiBreakingChain, GiPowerLightning } from "react-icons/gi";
let icons =
    [{ Icon: FaHeadSideVirus },
    { Icon: FaRocket },
    { Icon: GiBreakingChain },
    { Icon: GiPowerLightning },
    { Icon: FaPuzzlePiece },
    { Icon: FaLink }]
export const Home = (props: any) => {
    return (<div className="flex flex-col h-full m-3 ">
        <div className="flex min-h-2/3 flex-col w-100">
            <h3 className="text-goround w-100 ">Latest News</h3>
            <div className="flex w-full p-3 justify-center w-100 items-center" >
                <div className="grid grid-cols-3 gap-x-3 gap-y-8 w-full">
                    {dummy.videoImages.slice(0, 6).map((image, index) => {
                        return (<div key={index} className=" flex justify-center items-center">
                            <ImageLoading className="flex aspect-5/3 rounded w-full " style={{ objectFit: 'cover' }} src={image} />
                        </div>)
                    })}
                </div>
            </div>
            <div className="text-goround mx-3 place-self-end hover:cursor-pointer">See all</div>
        </div>
        <div className="flex flex-col h-1/3">
            <h3 className="text-goround flex  w-full m-2 ">What do you need?</h3>
            <div className="grid grid-cols-6 gap-3">
            </div>
            <div className="flex w-full mt-3 px-5 gap-8">
                {icons.map((Obj: any, index) => {
                    return (<div className="flex bg-white p-8 rounded-3xl">
                        <Obj.Icon size={'100%'} />
                    </div>)
                })}
            </div>
        </div>
    </div>)
}