import { ImageLoading } from "../components/ImageLoading"
import faker from 'faker'
const doctorImage = 'https://img.freepik.com/free-photo/pleased-young-female-doctor-wearing-medical-robe-stethoscope-around-neck-standing-with-closed-posture_409827-254.jpg?t=st=1647165169~exp=1647165769~hmac=3f4d90fa062b1725f5178d9e55e5b703a0e2f0aface69cae56fd5b3add60b120&w=2000'
export const Speaker = () => {
    return (<div>

        <div className="flex w-full p-8  h-1/2">

            <ImageLoading className="rounded-3xl flex aspect-square" src={doctorImage} />
            <div className="m-8 w-1/2">
                <h2>Dr / {faker.name.findName()}</h2>
                <h5 className="font-light">{faker.lorem.lines(12)}</h5>
            </div>
        </div>
        <div className="flex mx-8">
            <h5 className="font-light">{faker.lorem.lines(8)}</h5>
        </div>

    </div>)
}