import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { Layout } from "../screens/layout"
import { InterestsScreen } from "../screens/interests"
import SignIn from "../screens/signIn"
import { SignUp } from "../screens/signUp"
import { Library } from "../sub-screens/library"
import { Home } from "../sub-screens/home"
import { Favorites } from "../sub-screens/favorites"
import { Calendar } from "../sub-screens/calendar"
import { Request } from "../sub-screens/request"
import { Discussions } from "../sub-screens/discussions"
import { Books } from "../screens/categoriesScreens/Books"
import { Events } from "../screens/categoriesScreens/Events"
import { Gallery } from "../screens/categoriesScreens/Gallery"
import { Podcasts } from "../screens/categoriesScreens/Podcasts"
import { Articals } from "../screens/categoriesScreens/Articals"
import { Videos } from "../screens/categoriesScreens/Videos"
import { RequestSuccess } from "../sub-screens/requestSucess"
import { SingleArticle } from "../sub-screens/single-article"
import { Profile } from "../sub-screens/profile"
import { Event } from "../sub-screens/event"
import { Overview } from "../sub-screens/overview"
import { Speakers } from "../sub-screens/speakers"
import { Speaker } from "../sub-screens/speaker"
import { Agenda } from "../sub-screens/agenda"
import { SingleVideo } from "../sub-screens/single-video"
import { SinglePodcast } from "../sub-screens/single-podcast"
import { SingleDiscussion } from "../sub-screens/single-discussion"

export const GoRoundRoutes = () => {
    return (
        <Routes>
            <Route path="/signIn" element={<SignIn />} />
            <Route path="/signUp" element={<SignUp />} />
            <Route path="/interests" element={<InterestsScreen />} />
            <Route path="/" element={<Layout />} >
                <Route path="profile" element={<Profile />} />
                <Route path="event/:id/*" element={<Event />} >
                    <Route path="overview" element={<Overview />} />
                    <Route path="speakers" element={<Speakers />} />
                    <Route path="speakers/:id" element={<Speaker />} />
                    <Route path="agenda" element={<Agenda />} />
                </Route>
                <Route path="library" element={<Library />} >
                    <Route path="books" element={<Books />} />
                    <Route path="gallery" element={<Gallery />} />
                    <Route path="podcasts" element={<Podcasts />} />
                    <Route path="podcasts/:id" element={<SinglePodcast />} />
                    <Route path="Articals" element={<Articals />} >
                    </Route>
                    <Route path="events" element={<Events />} />
                    <Route path="videos" element={<Videos />} />
                    <Route path="videos/:id" element={<SingleVideo />} />
                    <Route path="articals/:id" element={<SingleArticle />} />
                </Route>
                <Route path="favorites" element={<Favorites />} >
                    <Route path="books" element={<Books />} />
                    <Route path="gallery" element={<Gallery />} />
                    <Route path="podcasts" element={<Podcasts />} />
                    <Route path="Articals" element={<Articals />} />
                    <Route path="events" element={<Events />} />
                    <Route path="videos" element={<Videos />} />
                    <Route path="videos/:id" element={<SingleVideo />} />
                    <Route path="podcasts/:id" element={<SinglePodcast />} />
                    <Route path="articals/:id" element={<SingleArticle />} />
                </Route>
                {/* <Route index element={<Home />} /> */}
                <Route path="/" element={<Navigate to={"home"} />} />
                <Route path="home" element={<Home />} />
                <Route path="calendar" element={<Calendar />} />
                <Route path="request" element={<Request />} />
                <Route path="requestSuccess" element={<RequestSuccess />} />
                <Route path="discussions" element={<Discussions />} />
                <Route path="discussions/:id" element={<SingleDiscussion />} />
            </Route>

        </Routes>

    )
}