import { useNavigate } from "react-router-dom"
import { ArticleCard } from "../../components/articleCard"
import { dummy } from "../../dummydata/faker"

export const Articals = () => {
    const navigate = useNavigate()
    function handleClick(index: any) {
        navigate(`../articals/${index}`)
    }
    return (<div>
        {dummy.lastNews.map((img, index) => {
            return (
                <ArticleCard onClick={handleClick} index={index} img={img} />
            )
        })}
    </div>)
}