import { useNavigate } from 'react-router-dom'
import { songsdata } from '../../components/audio-player/audios'
import Player from '../../components/audio-player/Player'
export const Podcasts = () => {
    const navigate = useNavigate()
    function navigatePodcast(index: any) {
        navigate(`${index}`)
    }
    return (<div className='flex gap-10 w-full flex-col'>
        {songsdata?.map((song: any, index: number) => {
            return (<Player navigatePodcast={() => navigatePodcast(index)} key={index} currentSong={song} />)
        })}
    </div>)
}