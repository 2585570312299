import { useEffect, useRef, useState } from 'react';
import '../components/audio-player/player.css';

import { FaCircle, FaClock, FaHeart, FaList, FaPause, FaPlay } from 'react-icons/fa';
import moment from 'moment';
import { ImageLoading } from '../components/ImageLoading';
import { songsdata } from '../components/audio-player/audios';
// import { songsdata } from './audios';
interface props {
    currentSong: any,
    navigatePodcast: () => void
}
export const SinglePodcast = () => {
    const [isplaying, setisplaying] = useState<any>(false);
    const [currentSong, setCurrentSong] = useState<any>(songsdata[0]);

    const clickRef = useRef<any>();

    const PlayPause = () => {
        setisplaying(!isplaying);
    }

    const checkWidth = (e: any) => {
        let width = clickRef?.current?.clientWidth;
        const offset = e.nativeEvent.offsetX;
        const divprogress = offset / width * 100;
        audioElem.current.currentTime = divprogress / 100 * currentSong.length;
    }

    // const skipBack = () => {
    //     const index = songsdata.findIndex((x: any) => x.title == currentSong.title);
    //     if (index == 0) {
    //         setCurrentSong(songsdata[songsdata.length - 1])
    //     }
    //     else {
    //         setCurrentSong(songsdata[index - 1])
    //     }
    //     audioElem.current.currentTime = 0;

    // }

    const audioElem = useRef<any>();
    useEffect(() => {
        if (isplaying) {
            audioElem.current.play();
        }
        else {
            audioElem.current.pause();
        }
    }, [isplaying])

    // const skiptoNext = () => {
    //     const index = songsdata.findIndex((x: any) => x.title == currentSong.title);

    //     if (index == songsdata.length - 1) {
    //         setCurrentSong(songsdata[0])
    //     }
    //     else {
    //         setCurrentSong(songsdata[index + 1])
    //     }
    //     audioElem.current.currentTime = 0;

    // }
    const [duration, setDuration] = useState<number>(0)

    const onPlaying = () => {
        const duration: any = audioElem.current.duration;
        const ct: any = audioElem.current.currentTime;
        setCurrentSong({ ...currentSong, "progress": ct / duration * 100, "length": duration })
    }
    const [progress, setProgress] = useState<any>(0)
    useEffect(() => {
        if (audioElem)
            setProgress(audioElem?.current?.currentTime)
    }, [audioElem])
    return (
        <div className='w-full h-full flex my-2 flex-col'>
            <audio src={currentSong.url} ref={audioElem} onLoadedMetadata={(e) => { setDuration(audioElem.current.duration) }} onTimeUpdate={onPlaying} />
            <div className='flex flex-col items-center'>
                <div className='flex flex-col w-full justify-center items-center  h-full '>
                    <div className='flex w-full flex-col'>
                        <h5>{currentSong.title}</h5>
                        <div>Dr / Lorem ipsum</div>

                        {/* <div className="flex text-xs gap-1 ">
                            <div className="flex gap-1  items-center">
                                <FaPlay />
                                <div>15 K</div>
                            </div>
                            <div className="flex gap-1 items-center">
                                <FaHeart />
                                <div>1 K</div>
                            </div>
                            <div className="flex gap-1 items-center">
                                <FaClock />
                                {duration && <div>{moment().startOf('day').seconds(duration).format('mm:ss')}</div>}
                            </div>
                        </div> */}
                    </div>
                    <div className='flex flex-col justify-centeritems-center w-3/12 my-3'
                    >
                        <img className="aspect-square rounded-full -mt-8"
                            src={currentSong.image} />
                        <div className='flex justify-between '>
                            <div className='flex gap-8 justify-between w-full m-3'>
                                <FaList className='hover:cursor-pointer' size={30} />
                                <FaHeart className='hover:cursor-pointer' size={30} />
                            </div>
                        </div>
                        <div className='flex gap-2 justify-center m-3 font-bold'>
                            {audioElem?.current?.currentTime ? <div>{moment().startOf('day').seconds(audioElem?.current.currentTime).format('mm:ss')}</div> : <div>00:00</div>}
                            <div>|</div>
                            {duration && <div>{moment().startOf('day').seconds(duration).format('mm:ss')}</div>}
                        </div>
                    </div>
                    <div className="flex flex-row w-10/12 justify-center items-center my-1">
                        <div className="flex navigation_wrapper mx-3" onClick={checkWidth} ref={clickRef}>
                            <div className={`flex justify-end rounded bg-goround w-0 h-full `} style={{ width: `${currentSong.progress + "%"}` }}>
                                <div className='absolute -m-2 border-solid bg-white text-white  rounded-full shadow-xl shadow-goround border-goround border-4'>
                                    <FaCircle />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex w-full flex-col p-2 rounded-md justify-center items-center mt-3'>
                        <div className='flex justify-start gap-2 items-center'>
                            <div className="flex">
                                {isplaying ? <FaPause size={50} className='hover:text-gray-700 hover:cursor-pointer  pp' onClick={PlayPause} /> : <FaPlay size={50} className='hover:text-gray-700 hover:cursor-pointer   pp' onClick={PlayPause} />}
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}