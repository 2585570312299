import { BsFillPeopleFill } from "react-icons/bs"
import { FaClock, FaHeart, FaPeopleCarry } from "react-icons/fa"

type Item = {
    title: string,
    doctors: string[],
    followers: number,
    likes: number,
    time: any,
    images: string[],
    details: string
}
interface props {
    item: Item,
    onClick?: () => void
}
export const UpcomingDiscussionCard = ({ item }: props) => {
    return (<div className="flex aspect-square gap-2 h-full hover:cursor-pointer bg-white rounded-2xl p-3 shadow-md">
        <div className="flex w-full flex-col mt-3">
            <div className="flex gap-1 ml-3 items-center">
                <FaClock /> <div>5:00 PM</div>
            </div>
            <div className="flex h-1/2 my-auto gap-3">
                <div className="flex relative w-6/12 h-1/2">
                    <img className="absolute bottom-1/3 w-4/6 right-0  aspect-square object-cover rounded-sm" src={item.images[0]} />
                    <img className="absolute top-1/3  w-4/6 left-0  aspect-square object-cover rounded-sm" src={item.images[0]} />
                </div>
                <div className="flex flex-col justify-evenly w-full h-full">
                    <h5 className="m-0 p-0 text-1.5vw">Lorem Room</h5>
                    <div className="text-1vw">
                        <div >Dr Lorem ipsum 1</div>
                        <div>Dr Lorem ipsum 2</div>
                    </div>
                    <div className="flex text-1vw gap-2  w-full">
                        <div className="text-gray-500">{item.details}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}