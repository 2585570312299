import { useEffect, useRef, useState } from 'react';
import './player.css';

import { FaCircle, FaClock, FaHeart, FaPause, FaPlay } from 'react-icons/fa';
import { ImageLoading } from '../ImageLoading';
import moment from 'moment';
// import { songsdata } from './audios';
interface props {
    currentSong: any,
    navigatePodcast: () => void
}
const Player = ({ currentSong: song, navigatePodcast }: props) => {
    const [isplaying, setisplaying] = useState<any>(false);
    const [currentSong, setCurrentSong] = useState<any>(song);

    const clickRef = useRef<any>();

    const PlayPause = () => {
        setisplaying(!isplaying);
    }
    const checkWidth = (e: any) => {
        let width = clickRef?.current?.clientWidth;
        const offset = e.nativeEvent.offsetX;
        const divprogress = offset / width * 100;
        audioElem.current.currentTime = divprogress / 100 * currentSong.length;
    }

    // const skipBack = () => {
    //     const index = songsdata.findIndex((x: any) => x.title == currentSong.title);
    //     if (index == 0) {
    //         setCurrentSong(songsdata[songsdata.length - 1])
    //     }
    //     else {
    //         setCurrentSong(songsdata[index - 1])
    //     }
    //     audioElem.current.currentTime = 0;

    // }

    const audioElem = useRef<any>();
    useEffect(() => {
        if (isplaying) {
            audioElem.current.play();
        }
        else {
            audioElem.current.pause();
        }
    }, [isplaying])

    // const skiptoNext = () => {
    //     const index = songsdata.findIndex((x: any) => x.title == currentSong.title);

    //     if (index == songsdata.length - 1) {
    //         setCurrentSong(songsdata[0])
    //     }
    //     else {
    //         setCurrentSong(songsdata[index + 1])
    //     }
    //     audioElem.current.currentTime = 0;

    // }
    const [duration, setDuration] = useState<number>(0)

    const onPlaying = () => {
        const duration: any = audioElem.current.duration;
        const ct: any = audioElem.current.currentTime;
        setCurrentSong({ ...currentSong, "progress": ct / duration * 100, "length": duration })
    }
    return (
        <div className='w-full h-full flex my-2 flex-col '>
            <audio src={currentSong.url} ref={audioElem} onLoadedMetadata={(e) => { setDuration(audioElem.current.duration) }} onTimeUpdate={onPlaying} />
            <div className='flex items-center'>
                <div className='flex w-full h-full '>
                    <div className='flex w-1/12 mr-3' onClick={navigatePodcast}>
                        <ImageLoading className="aspect-square hover:cursor-pointer hover:shadow-lg rounded-md" src={song.image} />
                    </div>
                    <div className='flex w-full  bg-white p-2 rounded-md shadow-md'>
                        <div className='flex justify-start  w-3/12 gap-2 items-center'>
                            <div className="flex w-2/12">
                                {isplaying ? <FaPause size={50} className='hover:text-gray-700 hover:cursor-pointer  pp' onClick={PlayPause} /> : <FaPlay size={50} className='hover:text-gray-700 hover:cursor-pointer   pp' onClick={PlayPause} />}
                            </div>
                            <div className='flex w-full flex-col'>
                                <h5 onClick={navigatePodcast} className="hover:cursor-pointer hover:text-blue-400">{currentSong.title}</h5>
                                <div >Dr / Lorem ipsum</div>

                                <div className="flex text-xs gap-1 ">
                                    <div className="flex gap-1  items-center">
                                        <FaPlay />
                                        <div>15 K</div>
                                    </div>
                                    <div className="flex gap-1 items-center">
                                        <FaHeart />
                                        <div>1 K</div>
                                    </div>
                                    <div className="flex gap-1 items-center">
                                        <FaClock />
                                        {duration && <div>{moment().startOf('day').seconds(duration).format('mm:ss')}</div>}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row w-full justify-center items-center mx-2">

                            <div className="flex  navigation_wrapper" onClick={checkWidth} ref={clickRef}>
                                <div className={`flex justify-end rounded bg-goround w-0 h-full `} style={{ width: `${currentSong.progress + "%"}` }}>
                                    <div className='absolute -m-2 border-solid bg-white text-white  rounded-full shadow-xl shadow-goround border-goround border-4'>
                                        <FaCircle />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Player