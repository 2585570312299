import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";

export async function muteAttendee(uid: any) {
    const docRef = doc(db, "peers", uid);
    const docSnap = await getDoc(docRef);
    await updateDoc(doc(db, "peers", uid), {
        muted: !docSnap.data()?.muted,
        raiseHand: false
    });
}