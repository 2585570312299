import { Outlet} from 'react-router-dom';
import { SideNav } from '../components/SideNav';
import { TopBarContent } from '../components/topBar';


export const Layout = () => {
    return (
        <div className="w-full flex flex-row">
            <div className='sideNav'>
                <SideNav />
            </div>
            <div className='flex-col w-full flex'>
                <div className={" w-full border-solid "} >
                    <TopBarContent />
                </div>
                <Outlet />
            </div>
        </div >
    )
}