import { Outlet, useLocation } from "react-router-dom"
import { CategoriesBar } from "../components/categoriesBar"
import { dummy } from "../dummydata/faker"

export const Favorites = (props: any) => {
    const location = useLocation()
    return (
        <div className="flex flex-col h-full m-3 p-3">
            <h3 className="text-goround  m-2 ">{location.pathname.includes('Favorites') ? "Favorites" : "Library"}</h3>
            <div>
                <CategoriesBar categories={dummy.categories} />
            </div>
            <div className="flex px-8">
                <Outlet />
            </div>
        </div>)
}