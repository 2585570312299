import { useSelector } from "react-redux"
import { Attendees } from "../components/live-discussion/attendees"
import { Moderators } from "../components/live-discussion/moderators"
import LiveVideoStreaming from "../screens/agora/agora"
import Agora from "../screens/agora/agora-screen"

export const SingleDiscussion = () => {
    const { myUid } = useSelector((state: any) => state.agora)
    return (<div className="flex flex-col p-9 gap-3 w-full ">
        My id : {myUid}
        <Moderators />
        <Attendees />
        <LiveVideoStreaming />
        {/* <Agora /> */}
    </div>)
}