import { useEffect, useState } from "react"
import { FaSignOutAlt } from "react-icons/fa"
import { useLocation, useNavigate } from "react-router-dom"
import { GoroundIcon } from "../assets/icons/GoroundIcon"
import { dummy } from "../dummydata/faker"
import { SidebarItem } from "../interfaces/interfaces"

let arr = dummy.sideBar
export const SideNav = (props: any) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [currentPath, setCurrentPath] = useState(location.pathname.split('/')[1])
    function navigateTo(path: string) {
        navigate(path)
    }
    useEffect(() => {
        setCurrentPath(location.pathname.split('/')[1])
    }, [location.pathname])
    return (
        <div className="w-64 sideNav sticky flex justify-center h-full border-r-2" aria-label="Sidebar">
            <div className="h-screen overflow-y-auto py-4 ">
                <div className="mx-4 flex flex-col justify-between h-full">
                    <div>
                        <GoroundIcon vertical />
                        <div className="my-4">
                            {arr && arr.map((item: SidebarItem, index) => {
                                return (
                                    <div key={index} className={`hover:cursor-pointer rounded ${currentPath.toLowerCase() === item.value.toLowerCase() && "bg-goround"} `} onClick={() => navigateTo(item.path)}>
                                        <h2 className="flex items-center p-2 text-base font-normal text-goround rounded-lg  ">
                                            <item.icon color={currentPath.toLowerCase() === item.value.toLowerCase() ? "white" : "rgba(10,80,150,1)"} />
                                            <span className={`ml-3 ${currentPath.toLowerCase() === item.value.toLowerCase() ? "text-white" : "text-goround"}  text-goround`}>{item.value}</span>
                                        </h2>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div>
                        <div className={`hover:cursor-pointer rounded  `} onClick={() => navigateTo("/signin")}>
                            <h2 className="flex items-center p-2 text-base font-normal text-goround rounded-lg  ">
                                <FaSignOutAlt />
                                <span className="ml-3 text-goround">Sign out</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}