
interface props {
    index: number,
    image: string,
    name: string,
    description: string,
    onCardClick: () => void
}
export const SpeakerCard = ({ index, image, name, description, onCardClick }: props) => {
    return (
        <div onClick={onCardClick} key={index} className="flex flex-row w-11/12 p-2 my-2 mt-4 rounded-xl shadow-lg hover:cursor-pointer">
            <img
                className="flex w-2/12 rounded-xl"
                src={image}
            />
            <div className="flex mx-2 gap-2 flex-col">
                <h5>{name}</h5>
                <div>{description}</div>
            </div>
        </div>
    )
}