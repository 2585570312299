import { useNavigate } from "react-router-dom"
import { ImageLoading } from "../../components/ImageLoading"
import { dummy } from "../../dummydata/faker"
export const Videos = () => {
    const navigate = useNavigate()
    function openVideo(id: number) {
        navigate(`${id}`)
    }
    return (<div className="flex w-full flex-col h-full ">
        <div className="grid grid-cols-3 w-full p-0 gap-4 justify-center items-center w-100">
            {dummy.videoImages.slice(0, 3).map((img, index) => {
                return (<div className="hover:cursor-pointer" onClick={() => openVideo(index)}><ImageLoading key={index} className="rounded h-3/4 aspect-4/3" src={img} /></div>)
            })}
        </div>
        <div className="text-goround place-self-end mx-8 hover:cursor-pointer">See all</div>
        <div className="flex flex-col w-full relative">
            <h3 className="text-goround flex m-2 ">Recommended for you</h3>
            <div className="flex w-full  gap-x-8">
                <div onClick={() => openVideo(0)} className="flex w-1/3 m-3 justify-center">
                    <ImageLoading className="flex hover:cursor-pointer rounded aspect-4/3" src={dummy.videoImages[0].toString()} />
                </div>
                <div className="flex w-2/3 pt-3">
                    <div className="grid grid-cols-3">
                        {dummy.videoImages.slice(0, 6).reverse().map((img, index) => {
                            return (
                                <div className="hover:cursor-pointer" onClick={() => openVideo(index)}>
                                    <ImageLoading key={index} className="rounded w-8/12 aspect-4/3" src={img} />
                                </div>)
                        })}
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}