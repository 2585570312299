import { dummy } from "../dummydata/faker"

export const Notification = (props: any) => {
    return (
        <div style={{ zIndex: '1000000000'}}>
            <div onClick={props.cancel} id="backdrop" style={{ position: "fixed", top: "0", left: "0", width: "100%", height: "100%", background: "rgba(155,155,155,0.3)" }}></div>
            <div className="notification">
                {dummy.notifications.slice(0, 8).map((notification) => {
                    return (
                        <div className="flex">
                            <div className="single-notification">
                                <div className="flex gap-1 w-10/12">
                                    <img style={{ width: '50px', height: '50px' }} className="flex rounded" src={notification.image} />
                                    <div className="text-goround">{notification.label}</div>
                                </div>
                                <div className="font-light w-2/12 justify-end flex text-goround">
                                    <div style={{ fontSize: '12px', display: 'flex', top: 0, right: 0 }}>1m ago</div>
                                </div>
                            </div>

                        </div>)
                })}

            </div>
        </div>)
}