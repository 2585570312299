import { FaClock } from "react-icons/fa"
interface props {
    events: any[],
    onEventClick: (event: any) => void;
}
export const TimelineComponent = (props: props) => {
    return (
        <div>
            {props.events?.length > 0 ? <ol className="relative border-l border-gray-500">
                {props.events && props.events?.map((event: any, index: any) => {
                    return (
                        <li onClick={() => props.onEventClick(event.id)} className="mb-10 ml-6 flex flex-row items-center gap-4">
                            <span className="flex absolute -left-16 justify-center items-center w-6 h-6rounded-full  ">
                                <div className="flex flex-row absolute -left-10 text-goround">
                                    <div>{event.time}</div>
                                    <div>-</div>
                                    <div>{event.endTime}</div>
                                </div>
                            </span>
                            <span className="flex absolute -left-2 justify-center items-center w-3 h-3 bg-goround rounded-full ring-4 ring-white ">
                            </span>
                            <div className="justify-between items-center p-4 hover:cursor-pointer hover:animate-pulse  bg-white rounded-lg border w-full border-gray-200 shadow-sm">
                                <div className="text-sm font-normal text-gray-500  flex items-center gap-1" ><FaClock /> {event.time} - {event.endTime} </div>
                                <div className="text-sm text-goround font-bold">{event.title} </div>
                            </div>
                        </li>
                    )
                })}
            </ol> : <div>No events for the selected date...</div>}

        </div>

    )
}