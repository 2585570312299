import { useEffect, useState } from "react"
import { BsFillPersonFill } from "react-icons/bs"

import { FaCircle, FaMicrophone, FaMicrophoneSlash, FaRegHandPaper } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { muteAttendee } from "../../helpers/actions"
import { peer } from "../../interfaces/interfaces"
import { agoraActions } from "../../store/store"
const initialState = {
    moderator: false,
    muted: true,
    uid: 0,
    user: 0,
    raiseHand: false,
    isTalking: false,
}
interface props {
    data?: peer,
}
export const Participant = ({ data = initialState }: props) => {
    const dispatch = useDispatch<any>()

    const [isSpeaking, setIsSpeaking] = useState(false)
    const [showActions, setShowActions] = useState(false)
    const { activeSpeakers } = useSelector((state: any) => state.agora)
    const { moderator, muted, uid, isTalking, raiseHand } = data

    useEffect(() => {
        if (activeSpeakers?.includes(uid.toString())) {
            setIsSpeaking(true)
        }
        else {
            setIsSpeaking(false)
        }
    }, [activeSpeakers])

    async function handleMute() {
        muteAttendee(uid)
    }
    return (
        <div className="flex flex-col gap-2">
            <div onClick={() => {
                setShowActions((prev) => !prev)

            }}>
                <div style={{ borderWidth: isSpeaking ? "2px" : "0px" }} className={`flex relative bg-gray-500 aspect-square rounded-xl  border-goround`}>
                    <div>
                        <BsFillPersonFill color="white" size={"100%"} />
                    </div>
                    <div className="absolute -right-2 -bottom-2 p-2 bg-green-500 rounded-full text-white">
                        {muted ? <FaMicrophoneSlash /> : <FaMicrophone />}
                    </div>
                    {raiseHand && <div className="absolute -left-2 -bottom-2 p-2 bg-green-500 rounded-full text-white">
                        <FaRegHandPaper />
                    </div>}
                </div>
                <div className="flex w-full justify-start items-center mt-2 gap-1">
                    {moderator && <FaCircle className="text-green-500" />}
                    <div className="text-sm self-center w-full flex">Dr / {uid}</div>
                </div>
            </div>
            {true && <div style={{ display: `${showActions ? "flex" : "none"}` }} className={`action-transition justify-center`}>
                <button onClick={() => {
                    setShowActions(false)
                    handleMute()
                }} className="btn-primary h-fit text-xs m-0 p-1">{muted ? 'unmute' : 'mute'}</button>
            </div>}
        </div >)
}