import { useEffect, useState } from "react"
import { Link, NavLink, useLocation } from "react-router-dom"
import { dummy } from "../dummydata/faker"
interface props {
    categories: string[]
}
export const CategoriesBar = ({ categories }: props) => {
    const location = useLocation()

    return (<nav className="flex w-full justify-around">
        {categories.map((category: any, index: any) => {
            return (
                <NavLink to={category} key={index} onClick={() => {
                }} className={`bar no-underline hover:cursor-pointer  rounded-xl m-2 flex  p-2 text-goround`
                }> {category}</NavLink>
            )
        })}
    </nav >)
}