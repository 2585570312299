import { useState } from "react"
import { Button, Modal, ModalBody, ModalDialog, ModalHeader, ModalTitle } from "react-bootstrap"
import { useSelector } from "react-redux"
import { Participant } from "./participant"

export const Attendees = () => {
    const { attendees } = useSelector((state: any) => state.agora)
    const [showModal, setShowModal] = useState<boolean>(false)

    return (<div className="flex w-full flex-col">
        {/* <Modal show={showModal} onHide={() => setShowModal(false)}>
            <div className="w-fit">
                <ModalHeader>Modal</ModalHeader>
                <ModalBody className="flex w-full justify-center">
                    <button className="btn-primary">Mute</button>
                </ModalBody>
            </div>
        </Modal> */}
        <h5 className="font-light">Attendees</h5>
        <div className="grid grid-cols-8 gap-4 w-full">
            {attendees?.length > 0 ? attendees.map((item: any, index: number) => {
                return <Participant key={index} data={item} />
            }) : <div className="flex text-gray-500 w-full">No attendees yet...</div>}

        </div>
    </div>)
}