import { useLocation, useNavigate } from "react-router-dom"
import { SpeakerCard } from "../components/speaker-card"
import { dummy } from "../dummydata/faker"

export const Speakers = () => {
    const location: any = useLocation().pathname.split('/')[2]
    let event: any = dummy.events[location]
    const navigate = useNavigate()
    function navigateSpeaker(index: any) {
        navigate(`${index}`)
    }
    return (<div className="flex flex-col w-full">
        {event.speakers.map((speaker: any, index: number) => {
            return (<SpeakerCard onCardClick={() => navigateSpeaker(index)} index={index} name={speaker.name} image={speaker.image} description={speaker.description} />)
        })}

    </div>)
}