import AgoraRTC from "agora-rtc-sdk";
import { RTC } from '../../interfaces/interfaces';

import { useEffect, useState } from "react";
import { db } from "../../firebase/firebase.js";
import { collection, onSnapshot, query } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { agoraActions } from "../../store/store";
import { deletePeerFromFirebase, getToken, insertPeerToFirebase, raiseHand } from "../../store/agora-slice";
import { FaRegHandPaper } from "react-icons/fa";
import { BsPlus } from "react-icons/bs";
import { ImExit } from 'react-icons/im'
var rtc: RTC = {
    client: null,
    joined: false,
    published: false,
    localStream: null,
    remoteStreams: [],
    params: {}
};

// Options for joining a channel
var option = {
    appID: "95a6c177413445189557b795537834bc",
    channel: "hello",
    uid: null,
    token: "", // retrieved from firestore
    key: '',
    secret: ''
}

function leaveEventHost() {
    rtc.localStream = AgoraRTC.createStream({
        streamID: rtc.params.uid,
        audio: false,
        video: false,
        screen: false,
    })
    rtc.client?.unpublish(rtc.localStream, function (err: any) {
        console.log("publish failed");
        console.error(err);
    })
    rtc.client?.leave(function (ev?: any) {
        console.log(ev)
        console.log('faaaaaaailed');
    })
}

function leaveEventAudience() {
    rtc.client?.unpublish(rtc.localStream as any)
    rtc.client?.leave(function () {
        console.log("client leaves channel");
        //……
    }, function (err: any) {
        console.log("client leave failed ", err);
        //error handling
    })
}
function LiveVideoStreaming(props: any) {
    const { option: op } = useSelector((state: any) => state.agora)
    const [activeSpeakers, setActiveSpeakers] = useState<string[]>([])
    useEffect(() => {
        option = op
    }, [op])
    const dispatch = useDispatch<any>()
    function muteAudio() {
        rtc.localStream?.muteAudio()
    }
    function unMuteAudio() {
        rtc.localStream?.enableAudio()
    }
    function joinChannel(role: string) {
        // leaveEventHost()
        // leaveEventAudience()
        // Create a client
        rtc.client = AgoraRTC.createClient({ mode: "live", codec: "h264" });
        // Initialize the client
        rtc.client.init(option.appID, function () {
            console.log("init success");
            // Join a channel
            rtc.client?.enableAudioVolumeIndicator()
            rtc.client?.join(option.token ?
                option.token : null,
                //@ts-ignore
                option.channel, option.uid ? +option.uid : null, async function (uid: string) {
                    console.log("join channel: " + option.channel + " success, uid: " + uid);
                    rtc.params.uid = uid;
                    rtc.client?.on("active-speaker", function (evt: any) {
                        console.log('activeeeee ::: ', evt.attr);
                        let temp = evt?.attr?.map((obj: any) => obj)
                        dispatch(agoraActions.setActiveSpeakers(temp))
                    })
                    rtc.client?.on("volume-indicator", function (evt: any) {
                        console.log('activeeeee indicator ::: ', evt.attr);
                        let temp = evt?.attr?.map((obj: any) => obj.uid)
                        dispatch(agoraActions.setActiveSpeakers(temp))
                    })

                    if (role === "host") {
                        rtc.client?.setClientRole("host");
                        // Create a local stream
                        rtc.localStream = AgoraRTC.createStream({
                            streamID: rtc.params.uid,
                            audio: true,
                            video: false,
                            screen: false,

                            //    audioSource: { kind: "audio" }
                        })

                        // Initialize the local stream
                        rtc.localStream.init(function () {
                            console.log("init local stream success");
                            rtc.localStream?.play("local_stream");
                            rtc.localStream?.play("remote_video", { fit: "contain" }, function (errState) {
                                console.log(errState);
                                alert('--')

                                if (errState && errState.status !== "aborted") {
                                    alert('error')
                                }
                            })

                            //@ts-ignore
                            rtc.client?.publish(rtc?.localStream, function (err: any) {
                                console.log("publish failed");
                                console.error(err);
                            })
                        }, function (err: any) {
                            console.error("init local stream failed ", err);
                        });
                        rtc.client?.on("connection-state-change", function (evt: any) {
                            console.log("audience", evt)
                        })
                    }
                    if (role === "audience") {
                        rtc.client?.on("connection-state-change", function (evt: any) {
                            console.log("audience", evt)
                        })

                        rtc.client?.on("stream-added", function (evt: any) {
                            var remoteStream = evt.stream;
                            var id = remoteStream.getId();
                            if (id !== rtc.params.uid) {
                                //@ts-ignore
                                rtc.client?.subscribe(remoteStream, function (err: any) {
                                    console.log("stream subscribe failed", err);
                                })
                            }
                            console.log('stream-added remote-uid: ', id);
                        });
                        rtc.client?.on("volume-indicator", function (evt: any) {
                            console.log('activeeeee ::: ', evt);
                        })

                        rtc.client?.on("stream-removed", function (evt: any) {
                            var remoteStream = evt.stream;
                            var id = remoteStream.getId();
                            console.log('stream-removed remote-uid: ', id);
                        });
                        rtc.client?.on("stream-subscribed", function (evt: any) {
                            var remoteStream = evt.stream;
                            var id = remoteStream.getId();
                            remoteStream.play("remote_video_");
                            console.log('stream-subscribed remote-uid: ', id);
                        })
                        //@ts-ignore
                        rtc.client?.on("stream-unsubscribed", function (evt: any) {
                            var remoteStream = evt.stream;
                            var id = remoteStream.getId();
                            remoteStream.pause("remote_video_");
                            console.log('stream-unsubscribed remote-uid: ', id);
                        })
                    }
                    rtc.client?.on("stream-added", () => {
                        console.log('addddd');

                    })
                }, function (err: any) {
                    console.error("client join failed", err)
                })
        }, (err: any) => {
            console.error(err);
        });
    }
    useEffect(() => {
        async function init() {
            dispatch(getToken())

            //@ts-ignore
            const q = query(collection(db, "peers"));
            const unsubscribe = onSnapshot(q, (querySnapshot: any) => {
                dispatch(agoraActions.setPeers([]))
                querySnapshot.forEach((doc: any) => {
                    dispatch(agoraActions.addPeer(doc.data()))
                });
            })
        }
        init()
        dispatch(agoraActions.setUid())
        isMute ? muteAudio() : unMuteAudio()
        return () => {
            muteAudio()
        }
    }, [])
    function handleJoinHost() {
        joinChannel('host') // join agora
        dispatch(insertPeerToFirebase(true)) // join firebase realtime
    }
    function handleJoinAudience() {
        joinChannel('audience')
        dispatch(insertPeerToFirebase(false))
    }
    async function reload() {
        // window.location.reload()
    }
    function handleLeaveEventAudience() {
        leaveEventAudience()
        dispatch(deletePeerFromFirebase())
        reload()
    }
    function handleLeaveEventHost() {
        leaveEventHost()
        dispatch(deletePeerFromFirebase())
        reload()
    }
    const [isMute, setIsMute] = useState<boolean>(false)
    function handleMute() {
        isMute ? rtc.localStream?.enableAudio() : rtc.localStream?.muteAudio()
        setIsMute((prev) => !prev)
    }
    return (
        <>
            <div className='flex gap-3'>
                <div>
                    <div className="flex gap-2">
                        <button className='btn-primary h-fit' onClick={() => handleJoinHost()}>Join Channel as Host</button>
                        <button className='btn-primary h-fit' onClick={() => handleJoinAudience()}>Join Channel as Audience</button>
                        <button className='btn-primary h-fit' onClick={() => handleLeaveEventHost()}>Leave Event Host</button>
                        <button className='btn-primary h-fit' onClick={() => handleLeaveEventAudience()}>Leave Event Audience</button>
                    </div>
                    <div className="m-3">
                        <button className='btn-primary h-fit' onClick={() => handleMute()}>{isMute ? "Unmute" : "Mute"}</button>
                    </div>
                </div>
                <div id="local_stream" className="local_stream" style={{ width: "400px", height: "400px" }}></div>
                <div
                    id="remote_video_"
                    style={{ width: "400px", height: "400px", display: 'none' }}
                />
            </div>
            <div className="fixedElement justify-center flex ">
                <div className="flex w-64 h-12 relative justify-between items-center p-1 bg-white rounded-md">
                    <ImExit size={"90%"} />
                    <FaRegHandPaper onClick={() => dispatch(raiseHand())} size={"140"} className="h-fit w-fit p-3 rounded-full shadow-md bg-white hover:cursor-pointer" />
                    <BsPlus size={"100%"} />
                </div>
            </div>
        </>
    );
}

export default LiveVideoStreaming;