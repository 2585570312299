import faker, { lorem } from 'faker'
import { BsFillPeopleFill, BsHeart } from 'react-icons/bs'
import { ImageLoading } from '../components/ImageLoading'
const image = "https://media.istockphoto.com/photos/headshot-portrait-of-smiling-male-doctor-with-tablet-picture-id1311511363?k=20&m=1311511363&s=612x612&w=0&h=FBV6v4Dh1pvpktzJIEcIhI6obktwrC2Mrsmr_6CW8fY="
export const Profile = () => {
    return (
        <div className="flex w-full text-goround flex-col h-full p-3">
            <div>
                <div>
                    <div className='flex w-full h-1/4 gap-3'>
                        <ImageLoading className='rounded-xl border-8 aspect-square border-white w-2/6 h-full' style={{ width: '250px', height: '250px', objectFit: 'cover' }} src={image} />
                        <div className='flex w-full flex-col h-fit rounded-xl p-3' style={{ backgroundColor: 'rgba(12,115,180,0.2)' }}>
                            <h4>About</h4>
                            <div className='flex h-full'>{lorem.lines(4)}</div>
                        </div>
                    </div>
                </div>
                <div className='mx-3 my-4'>
                    <h3>Dr / {faker.name.findName()}</h3>
                    <div className='flex gap-3'>
                        <div className='flex gap-1 items-center'>
                            <BsFillPeopleFill />
                            200
                        </div>
                        <div className='flex gap-1 items-center'>
                            <BsHeart />
                            2K
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex w-full flex-col h-fit rounded-xl p-3' style={{ backgroundColor: 'rgba(12,115,180,0.2)' }}>
                <h4>Experience</h4>
                <div>{lorem.lines(10)}</div>
            </div>
        </div>)
}