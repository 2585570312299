import { Outlet } from 'react-router-dom'
import { EventBar } from '../components/eventBar'
let arr = ["Overview", "Speakers", "Agenda"]

export const Event = () => {

    return (<div className="flex flex-col text-goround w-full h-full pt-4 ">
        <EventBar categories={arr} />
        <div className='flex p-10'>
            <Outlet />
        </div>
    </div>)
}