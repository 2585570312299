import { useState } from "react"
import { Modal, ModalBody } from "react-bootstrap"
import { dummy } from "../../dummydata/faker"
import faker from 'faker'
import { FaDownload, FaHeart } from "react-icons/fa"
import { ImageLoading } from "../../components/ImageLoading"
export const Books = () => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [selectedBook, setSelectedBook] = useState<string>('')

    return (
        <div className="flex w-full flex-col ">
            <Modal onHide={() => setShowModal(false)} show={showModal}>
                <ModalBody >
                    <div className="flex gap-2 h-full w-full">
                        <div className="flex w-2/5">
                            <ImageLoading className=" flex rounded w-full h-full aspect-16/9 object-cover" src={selectedBook} />
                        </div>
                        <div className="flex w-3/5 flex-col">
                            <h2 className="text-goround font-bold">{faker.lorem.lines(1)}</h2>
                            <div className="text-goround">{faker.lorem.lines(3)}</div>
                            <div className="flex gap-3 w-full my-3 justify-around">
                                <FaDownload size={25} className="text-goround" />
                                <FaHeart size={25} className="text-goround" />
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <div className="grid grid-cols-5 w-full">
                {dummy.bookImages.slice(0, 5).map((img, index) => {
                    return (<ImageLoading key={index.toString()} onClick={() => {
                        setShowModal(true)
                        setSelectedBook(img)
                    }} className=" hover:cursor-pointer flex h-full rounded aspect-16/9 p-3 object-fill" style={{ objectFit: 'cover' }} src={img} />)
                })}
            </div>
            <div className="text-goround place-self-end hover:cursor-pointer">See all</div>
            <div className="flex flex-col">
                <h3 className="text-goround  m-2 ">Recommended for you</h3>
                <div className="grid grid-cols-5 w-full">
                    {dummy.bookImages.slice(0, 5).map((img, index) => {
                        return (<ImageLoading key={index.toString()} onClick={() => {
                            setShowModal(true)
                            setSelectedBook(img)
                        }} className=" hover:cursor-pointer flex h-full rounded aspect-16/9 p-3 object-fill" style={{ objectFit: 'cover' }} src={img} />)
                    })}
                </div>
            </div>
        </div>

    )
}