import './App.css';
import { GoRoundRoutes as AllRoutes } from './routes/routes';
function App() {
  return (
    <div className='text-goround App-background'>
      <AllRoutes />
    </div>

  );
}

export default App;

