import { useSelector } from "react-redux"
import { Participant } from "./participant"

export const Moderators = () => {
    const { moderators } = useSelector((state: any) => state.agora)
    return (<div className="flex gap-2 flex-col w-full">

        <h5 className="font-light">Moderators</h5>
        <div className="grid grid-cols-6 w-full gap-4 ">
            {moderators?.length > 0 ? moderators.map((item: any, index: number) => {
                return <Participant key={index} data={item} />
            }) : <div className="flex text-gray-500 w-full">No moderators yet ...</div>}
        </div>
    </div>)
}