import { FaClock } from "react-icons/fa"
import { dummy } from "../dummydata/faker"
import faker from 'faker'
import { useLocation } from "react-router-dom"
import { ImageLoading } from "../components/ImageLoading"
export const Overview = () => {
    const location: any = useLocation().pathname.split('/')[2]
    let event: any = dummy.events[location]
    return (
        <div className="flex flex-col">
            <h3>{event?.title}</h3>
            <div className='flex w-full justify-center'>
                <ImageLoading src={faker.image.abstract()} className="w-6/12 aspect-video flex h-px-10 object-contain rounded-xl" />
            </div>
            <div className='flex justify-between w-full '>
                <div className="flex gap-3">
                    {event.date}
                    <div className='flex items-center gap-1'>
                        <FaClock />
                        {event.time}
                        -
                        {event.endTime}
                    </div>
                </div>
                {/* <div className="flex">Starts after</div> */}

            </div>
            <div className='flex  flex-col gap-2'>
                <div className='flex flex-col  bg-white rounded-xl p-5'>
                    <div className="bg-emerald-500 p-2 rounded-lg text-white place-self-end">registered</div>
                    <h3>{event.title}</h3>
                    <div>{faker.lorem.lines(15)}</div>
                </div>
            </div>
        </div>
    )
}