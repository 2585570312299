import { AiOutlineCheckCircle } from "react-icons/ai"


export const RequestSuccess = () => {
    return (<div className="flex w-full h-full flex-col justify-around items-center">
        <div className="flex flex-col justify-around items-center h-3/5 text-goround ">
            <h1>Thank you</h1>
            <AiOutlineCheckCircle className="text-green-500" size={250} />
            <h5>Your request has been recieved</h5>
        </div>
    </div>)
}