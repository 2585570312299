import { useState } from "react"
import { ImageLoading } from "../../components/ImageLoading"
import { dummy } from "../../dummydata/faker"
export const Gallery = () => {
    const [selectedImage, setSelectedImage] = useState(dummy.lastNews[0])
    return (<div className="flex flex-col w-full h-full py-3">
        <div className="w-full flex h-2/4 justify-center">
            <img style={{ objectFit: 'cover' }} className="rounded-xl w-3/4" src={selectedImage} />
        </div>
        <div className="flex gap-3 my-3">
            {dummy.lastNews.slice(0, 4).map((img, index) => {
                return (<div key={index} className={`${selectedImage == img && "border-4 "} border-goround rounded-xl hover:cursor-pointer hover:animate-pulse`} onClick={() => { setSelectedImage(img) }}>
                    <ImageLoading src={img} />
                </div>
                )
            })}
        </div>

    </div>)
}