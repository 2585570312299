import { useNavigate } from "react-router-dom"
import { GoroundIcon } from "../assets/icons/GoroundIcon"
const SignIn = () => {
    const navigate = useNavigate()
   
    
    return (
        <div className="flex-col lg:flex-row flex h-12 sm:12  lg:h-screen md:h-screen">
            <div className="flex  lg:w-3/5 lg:h-1/1 md:h-1/1 justify-center bg-gray-100  items-center  ">
                <div className="flex w-2/5">
                    <GoroundIcon />
                </div>
            </div>
            <div className="lg:w-2/5 flex items-center">
                <form className="flex-col flex justify-center w-full p-5 items-center ">
                    <h1 className="font-bold text-goround m-3 text-2xl">Login</h1>
                    <input  placeholder="Email or username" className="input-primary" type={"text"} />
                    <input placeholder="Password" className="input-primary" type={"password"} />
                    <div className="flex flex-row justify-between place-content-between w-10/12 my-4 text-goround ">
                        <div className="flex justify-end w-100">
                            <h1 className="font-bold hover:cursor-pointer text-sm lg:text-lg md:text-md">Forgot password?</h1>
                        </div>
                    </div>
                    <div>
                        <button onClick={() => { navigate("/home") }} className="btn-primary w-40 text-2xl"><div className="">Login</div></button>
                    </div>
                    <div className="flex flex-row m-3">
                        <div className="">Don't have an account?</div>
                        <div onClick={() => navigate("/signUp")} className="text-goround font-bold hover:cursor-pointer ml-2">Sign Up</div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default SignIn