import faker, { lorem } from 'faker'
import { FaClock } from 'react-icons/fa'
import { ImageLoading } from '../components/ImageLoading'
export const SingleArticle = () => {
    return (<div className="flex m-2 gap-2 text-goround w-11/12 h-full flex-col">
        <div className='flex gap-2 items-center'>
            <ImageLoading className='w-64 object-cover rounded-xl h-64' src={faker.image.abstract()} />
            <div>
                <h2 className='font-bold'>{faker.lorem.sentence()}</h2>
                <h6>Dr / {faker.name.findName()}</h6>
                <div className='flex justify-start gap-1 items-center'>
                    <FaClock />
                    <div>20 m</div>
                </div>
            </div>
        </div>
        <div className='w-full'>{lorem.lines(20)}</div>
    </div>)
}