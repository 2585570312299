import { BsFillPeopleFill } from "react-icons/bs"
import { FaClock, FaHeart } from "react-icons/fa"

type Item = {
    title: string,
    doctors: string[],
    followers: number,
    likes: number,
    time: any,
    images: string[]
}
interface props {
    item: Item,
    onClick: () => void 
}
export const DiscussionCard = ({ item, onClick }: props) => {
    return (<div onClick={() => onClick()} className="flex hover:cursor-pointer aspect-3/1 gap-2 items-center min-h-fit  bg-white rounded-2xl p-2 shadow-md">
        <div className="flex relative w-4/12 h-full">
            <img className="absolute bottom-1/3 w-4/6 right-0  aspect-square object-cover rounded-sm" src={item.images[0]} />
            <img className="absolute top-1/3  w-4/6 left-0  aspect-square object-cover rounded-sm" src={item.images[0]} />
        </div>
        <div className="flex flex-col justify-evenly w-full h-full">
            <h5 className="m-0 p-0 text-1.5vw">Lorem Ipsum</h5>
            <div className="text-1vw">
                <div >Dr Lorem ipsum 1</div>
                <div>Dr Lorem ipsum 2</div>
            </div>
            <div className="flex text-0.8vw gap-2  w-full">
                <div className="flex justify-center gap-1 items-center"><BsFillPeopleFill /> <div>200</div></div>
                <div className="flex justify-center gap-1 items-center"><FaHeart /> <div>2 K</div></div>
                <div className="flex justify-center gap-1 items-center"><FaClock /> <div>Start 1 hour ago</div></div>
            </div>
        </div>
    </div>)
}