import { lorem } from "faker"
import moment from "moment"
import {  useRef, useState } from "react"
import { FaClock, FaHeart, FaPlay } from "react-icons/fa"

const link = "https://creativesad.com/Kaplan%20Medical%20USMLE%20Content.mp4"

export const SingleVideo = () => {
    const videoRef = useRef<any>()
    const [duration, setDuration] = useState<any>()
    function handleMetaData() {
        const video = videoRef.current
        setDuration(video.duration)
    }
    return (<div className="flex w-full h-full p-3">
        <div className="flex flex-col">
            <div className="flex justify-center flex-col">
                <h3 className="mx-3">Lorem Ipsum</h3>
                <video
                    ref={videoRef}
                    className="rounded self-center aspect-video w-full"
                    src={link}
                    disablePictureInPicture
                    controlsList="nodownload"
                    controls
                    id="video1"
                    playsInline
                    onLoadedMetadata={handleMetaData}
                >
                    {/* <source id="mp4" src={link} type="video/mp4" /> */}
                    <p>Your user agent does not support the HTML5 Video element.</p>
                </video>
            </div>
            <div className="flex m-3 text-xl gap-10">
                <div className="flex gap-1 items-center">
                    <FaPlay />
                    <div>15 K</div>
                </div>
                <div className="flex gap-1 items-center">
                    <FaHeart />
                    <div>1 K</div>
                </div>
                <div className="flex gap-1 items-center">
                    <FaClock />
                    {duration && <div>{moment().startOf('day').seconds(duration).format('mm:ss')}</div>}
                </div>

            </div>
            <div>
                <h3>Dr / Lorem Ipsum</h3>
                <div>{lorem.lines(10)}</div>
            </div>
        </div>
    </div>)
}