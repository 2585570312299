import { useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { FaBell } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { ImageLoading } from './ImageLoading'
import { Notification } from './notification'
const image = "https://media.istockphoto.com/photos/headshot-portrait-of-smiling-male-doctor-with-tablet-picture-id1311511363?k=20&m=1311511363&s=612x612&w=0&h=FBV6v4Dh1pvpktzJIEcIhI6obktwrC2Mrsmr_6CW8fY="
export const TopBarContent = () => {
    const [showNotification, setShowNotification] = useState(false)
    const navigate = useNavigate()
    function handleNotification() {
        setShowNotification((prev) => !prev)
    }
    function gotoProfile(){
        navigate('/profile')
    }
    return (<div className='flex justify-between items-center py-1  border-b-2 mx-6'>
        <div className='flex items-center px-3 w-3/5'>
            <input placeholder='Search' type={"text"} className="input-primary" />
            <BiSearch fontSize={10} size={40} className='text-white bg-goround rounded-full cursor-pointer shadow-lg p-2 ' />
        </div>
        <div className='flex items-center m-1 mx-1'>
            <div>
                <FaBell onClick={handleNotification} size={25} className='mx-4 text-goround hover:cursor-pointer' />
                {showNotification && <Notification cancel={() => setShowNotification(false)} />}
            </div>
            <div onClick={gotoProfile} className='flex hover:cursor-pointer items-center'>
                <ImageLoading loading='lazy' width={"60"} style={{ display: 'flex', width: '4rem', height: '4.1rem' }} height="60" className='rounded-md border-solid border-4 border-white' src={image} />
                <div className='mx-2 text-goround'>Lorem ipsum</div>
            </div>
        </div>
    </div>)
}