import { useNavigate } from "react-router-dom"
import { GoroundIcon } from "../assets/icons/GoroundIcon"
import { InterestItem } from "../components/InterestItem"
import { dummy } from "../dummydata/faker"

export const InterestsScreen = () => {
    const navigate = useNavigate()
    function signUp() {
        navigate("/home")
    }
    return (<div
        className="flex   h-screen m-3 flex-col items-center">
        <div className="w-100 justify-start rounded-none flex">
            <div className="flex w-2/12 h-fit ">
                <GoroundIcon vertical />
            </div>
        </div>
        <div className="flex justify-center items-center flex-col">
            <h1 className="text-2xl font-bold text-goround">Sign up</h1>
            <h1 className="text-2xl text-goround">Choose your interests</h1>
        </div>
        <div className="grid grid-cols-2  w-100">
            <div className="m-3 ">
                <h3 className="text-goround  font-light">Medical</h3>
                <div className="grid p-2 border-2 rounded-lg grid-cols-3  ">
                    {dummy.medical.map((interest, index) => {
                        return <InterestItem key={index} title={interest.value} />
                    })}
                </div>
            </div>
            <div className="m-3">
                <h3 className="text-goround font-light">Features</h3>
                <div className="grid p-2 rounded-lg border-2 grid-cols-3  ">
                    {dummy.medical.map((interest, index) => {
                        return <InterestItem key={index} title={interest.value} />
                    })}
                </div>
            </div>

        </div>
        <div className="flex justify-center items-center flex-col ">
            <div className="flex flex-row w-full text-goround">
                <input type={"checkbox"} className="w-10" />
                <div className="">
                    I Have read and accept the terms and conditions.
                    I confirm the validity of all provided personal data
                    and consent to thier processing in order to use all
                    the functions of the user's Cabinet.
                </div>
            </div>
            <button onClick={signUp} className="btn-primary my-3">Sign Up</button>
        </div>
    </div>)
}