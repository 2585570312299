// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
    apiKey: "AIzaSyCSrkyDGcCr09_lhdxdCd4ABmm9EyY-yTw",
    authDomain: "goround-app.firebaseapp.com",
    databaseURL: "https://goround-app-default-rtdb.firebaseio.com",
    projectId: "goround-app",
    storageBucket: "goround-app.appspot.com",
    messagingSenderId: "147659781938",
    appId: "1:147659781938:web:a130ff7c930e6ac800892b",
    measurementId: "G-Z2KXSRWH7Q"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
